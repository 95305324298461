import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'

export default {
  filterAndSearch (apiFilter, filter, search) {
    if (search) {
      const apiFilterSearch = new APIFilter()
      apiFilterSearch
        .setOperator(API_FILTER_OPERATORS.OR)
        .addILike('nombre', search)
        .addILike('email', search)
        .addILike('telefono', search)
      apiFilter.addNestedFilter(apiFilterSearch)
    }
    if (filter.nombre.value) {
      apiFilter.addILike(filter.nombre.field, filter.nombre.value)
    }
    if (filter.email.value) {
      apiFilter.addILike(filter.email.field, filter.email.value)
    }
    if (filter.telefono.value) {
      apiFilter.addILike(filter.telefono.field, filter.telefono.value)
    }
  },
  async selectClienteTelefono (Vue, filter, search, sorter, page, idcliente, idsistema, idclientePotencial) {
    let idclienteView = idcliente
    const apiFilterCliente = new APIFilter()
    if (idsistema) {
      if (!idclienteView) {
        const apiFilter = new APIFilter()
        apiFilter.addExact('idsistema', idsistema)
        const resp = await Vue.$api.call('sistema.select', { filter: apiFilter })
        idclienteView = resp.data.result.dataset[0].idcliente
      }
      return await Vue.$online.sistemaTelefono.selectContactosDelSistema(idsistema, idclienteView)
    } else {
      if (idclientePotencial) {
        apiFilterCliente.addExact('idcliente_potencial', idclientePotencial)
      } else {
        apiFilterCliente.addExact('idcliente', idclienteView)
      }
      apiFilterCliente.addGT('estado', 0)
      this.filterAndSearch(apiFilterCliente, filter, search)
      const respClienteTelefono = await Vue.$api.call(
        'clienteTelefono.select', {
          page,
          filter: apiFilterCliente,
          sorter,
        },
      )
      return [respClienteTelefono.data.result.dataset, respClienteTelefono.data.result.metadata]
    }
  },
  async selectClienteTelefonoRows (Vue, pks, idsistema) {
    const tieneContactosDeSistema = !!idsistema && (await Vue.$online.sistemaTelefono.tieneContactosDeSistema(idsistema))
    if (tieneContactosDeSistema) {
      const apiFilter = new APIFilter()
      apiFilter.addExact('idsistema', idsistema)
      apiFilter.addIn('idcliente_telefono', pks)
      const resp = await Vue.$api.call('sistemaTelefono.select', { filter: apiFilter })
      return [resp.data.result.dataset, resp.data.result.metadata]
    } else {
      const apiFilter = new APIFilter()
      apiFilter.addIn('idcliente_telefono', pks)
      if (idsistema) {
        apiFilter.addExact('para_todos_los_sistemas', 1)
      }
      const resp = await Vue.$api.call('clienteTelefono.select', { filter: apiFilter })
      return [resp.data.result.dataset, resp.data.result.metadata]
    }
  },
  async deleteSistemaTelefono (Vue, idsistemaTelefono) {
    await Vue.$api.call('sistemaTelefono.delete', { idsistema_telefono: idsistemaTelefono })
  },
  async deleteClienteTelefono (Vue, idclienteTelefono) {
    await Vue.$api.call('clienteTelefono.delete', { idcliente_telefono: idclienteTelefono })
  },
}
